import React, { Component } from "react";
import { Modal, Label, Icon, Button } from "semantic-ui-react";

export default class PartidaModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getStageName = (id) => {
    switch (id) {
      case 0:
        return 'Incidencia';
      case 1:
        return 'Desviste';
      case 2:
        break;
      case 3:
        return 'Molino';
      case 4:
        return 'Vulcanizado';
      case 5:
        return 'Desmonte';
      case 6:
        return 'Desbaste';
      case 7:
        return 'Rectificado';
      case 8:
        return 'Corte';
      case 9:
        return 'Contrucción';
      case 10:
        return 'Finalizado';
      case 11:
        return 'Pend. Vulcanziado';
      case null:
        return 'SIN SEGUIMIENTO';

      default:
        break;
    }
  }

  render() {
    console.log(this.props.data);
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          ORDEN DE TRABAJO: {this.props.data.pedido_id} / PARTIDA: {this.props.data.id}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <div>
            {this.props.data.rodillo ? (
              this.props.data.rodillo.length ? (
                this.props.data.rodillo.map((r) => (
                  <div key={r.id} style={{ textAlign: 'center', marginBottom: '10px' }} >
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}> RODILLO: {r.id} - </span>
                    <Label basic color="blue">
                      {this.getStageName(r.stage)}
                    </Label>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <span style={{ fontWeight: 'bold', fontSize: '18px' }} > RODILLO: {this.props.data.rodillo.id} - </span>
                  <Label basic color="blue">
                    {this.getStageName(this.props.data.rodillo.stage)}
                  </Label>
                </div>
              )
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
