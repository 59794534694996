import React from "react";
import { Route } from "react-router-dom";
import { services } from "../redux/FeathersRedux";
import HomeContainer from "../containers/HomeContainer";
import ClientsContainer from "../containers/ClientsContainer";
import ProvidersContainer from "../containers/ProvidersContainer";
import ProvidersEdit from "../components/Providers/ProvidersEdit";
import ContactsContainer from "../containers/ContactsContainer";
import OrdersContainer from "../containers/OrdersContainer";
import CheckContainer from "../containers/Tasks/CheckContainer";
import WindmillContainer from "../containers/Tasks/WindmillContainer";
import UndressContainer from "../containers/Tasks/UndressContainer";
import ConstructionRodilloContainer from "../containers/Tasks/ConstructionRodilloContainer";
import ConstructionManchetasContainer from "../containers/Tasks/ConstructionManchetasContainer";
import ConstructionPoliuretanoContainer from "../containers/Tasks/ConstructionPoliuretanoContainer";
import UnmountContainer from "../containers/Tasks/UnmountContainer";
import CuttingContainer from "../containers/Tasks/CuttingContainer";
import VulcanizationContainer from "../containers/Tasks/VulcanizationContainer";
import RoughingContainer from "../containers/Tasks/RoughingContainer";
import RectifiedContainer from "../containers/Tasks/RectifiedContainer";
import QAContainerOthers from "../containers/Tasks/QAContainerOthers";
import QAContainerRodillos from "../containers/Tasks/QAContainerRodillos";
import QAContainerManchetas from "../containers/Tasks/QAContainerManchetas";
import FormulasContainer from "../containers/FormulasContainer";
import RawMaterialsContainer from "../containers/RawMaterialsContainer";
import RawMaterialsStockContainer from "../containers/RawMaterialsStockContainer";
import ProfilesContainer from "../containers/ProfilesContainer";
import UsersContainer from "../containers/UsersContainer";
import UsersEditContainer from "../containers/UsersEditContainer";
import InventoryRawMaterialContainer from "../containers/InventoryRawMaterialContainer";
import IncidencesReportContainer from "../containers/IncidencesReportContainer";
import IndicatorsContainer from "../containers/IndicatorsContainer";
import IncidenceViewContainer from "../containers/IncidenceViewContainer";
import EmployeesReportContainer from "../containers/EmployeesReportContainer";
import GluesContainer from "../containers/GluesContainer";
import OrdersReportContainer from "../containers/OrdersReportContainer";
import FinishedProductReportContainer from "../containers/FinishedProductReportContainer";
import TaskReportContainer from "../containers/TaskReportContainer";
import CompositeInventoryReportContainer from "../containers/CompositeInventoryReportContainer";
import OrdersEditForm from "../components/Orders/OrdersEditForm";
import CompoundStockContainer from "../containers/CompoundStockContainer";
import MaterialContainer from "../containers/MaterialContainer";
import FreightContainer from "../containers/FreightContainer";
import ColorContainer from "../containers/ColorContainer";
import DrawContainer from "../containers/DrawContainer";
import NotificationSettingsContainer from "../containers/NotificationSettingsContainer";
import NotificationTaskContainer from "../containers/NotificationTaskContainer";
import ManchetasContainer from "../containers/ManchetasContainer";
import ManchetasEdit from "../components/Manchetas/ManchetasEdit";
import GeneralSettingsContainer from "../containers/GeneralSettingsContainer";
import CaptureReportContainer from "../containers/CaptureReportContainer";
import ManchetasReportContainer from "../containers/ManchetasReportContainer";
import CompoundDiscountStockContainer from "../containers/CompoundDiscountStockContainer";
import RawMaterialsStockDiscountContainer from "../containers/RawMaterialStockDiscountContainer";
import StockMovementsReportContainer from "../containers/StockMovementsReportContainer";
import TypeMachineContainer from "../containers/TypeMachineContainer";
import CatalogEquipmentContainer from "../containers/CatalogEquipmentContainer";
import EquipmentEditContainer from "../containers/EquipmentEditContainer";
import EquipmentInfoContainer from "../containers/EquipmentInfoContainer";
//importamos el contenedor de permiso
import RolePermissionContainer from "../containers/RolePermissionContainer";
import RoleEditContainer from "../containers/RoleEditContainer";

import InicioTarea from "../containers/InicioTareas";
import CalendarContainer from "../containers/Tasks/CalendarContainer";
import TaskTypeContainer from "../containers/TaskTypeContainer";
import MaintenanceTypeContainer from "../containers/MaintenanceTypeContainer";
import ISOCodesContainer from "../containers/ISOCodesContainer";
import FoldersBinghamContainer from "../containers/FoldersBinghamContainer";
import ProductsContainer from "../containers/ProductsContainer";

import CalendarTaskReportContainer from "../containers/CalendarTaskReportContainer";
import CalendarTaskTypeReportContainer from "../containers/CalendarTaskTypeReportContainer";
import CalendarTaskIsoReportContainer from "../containers/CalendarTaskIsoReportContainer";
import CalendarTaskIsoCodeReportContainer from "../containers/CalendarTaskIsoCodeReportContainer";
import FoldersBinghamReportContainer from "../containers/FoldersBinghamReportContainer";
import DocumentsBinghamReportContainer from "../containers/DocumentsBinghamReportContainer";
import TechnicalSheetsBinghamContainer from "../containers/TechnicalSheetsBinghamContainer";
import DrawFilesContainer from "../containers/DrawFilesContainer";
import TechnicalSheetsGeneralBinghamContainer from "../containers/TechnicalSheetsGeneralBinghamContainer";

import InvoiceContainer from "../containers/InvoiceContainer";
import RemissionContainer from "../containers/RemissionContainer";

import ViewTask from "../containers/Tasks/HomeCheckTasks/ViewTask";
import RoughingContainerTask from "../containers/Tasks/HomeCheckTasks/RoughingContainerTask";
import WindmillContainerTask from "../containers/Tasks/HomeCheckTasks/WindmillContainerTask";
import UnmountContainerTask from "../containers/Tasks/HomeCheckTasks/UnmountContainerTask";
import CheckContainerTask from "../containers/Tasks/HomeCheckTasks/CheckContainerTask";
import UndressContainerTask from "../containers/Tasks/HomeCheckTasks/UndressContainerTask";
import RectifiedContainerTask from "../containers/Tasks/HomeCheckTasks/RectifiedContainerTask";
import CuttingContainerTask from "../containers/Tasks/HomeCheckTasks/CuttingContainerTask";
import ConstructionRodilloContainerTask from "../containers/Tasks/HomeCheckTasks/ConstructionRodilloContainerTask";
import ConstructionPoliuretanoContainerTask from "../containers/Tasks/HomeCheckTasks/ConstructionPoliuretanoContainerTask";
import ConstructionManchetasContainerTask from "../containers/Tasks/HomeCheckTasks/ConstructionManchetasContainerTask";
import QAContainerRodillosTask from "../containers/Tasks/HomeCheckTasks/QAContainerRodillosTask";
import QAContainerManchetasTask from "../containers/Tasks/HomeCheckTasks/QAContainerManchetasTask";
import QAContainerOthersTask from "../containers/Tasks/HomeCheckTasks/QAContainerOthersTask";
import VulcanizationTask from "../containers/Tasks/HomeCheckTasks/VulcanizationTask";
import PartidasContainer from "../containers/PartidasContainer";
import IncidenceCheckContainer from "../containers/IncidenceCheckContainer";
import GeneralReportContainer from '../containers/GeneralReportContainer';
import DetailVulcanizationContainer from "../containers/Tasks/DetailVulcanizationContainer";
import VulcanizationTaskInfo from "../containers/Tasks/HomeCheckTasks/VulcanizationTaskInfo";

const noPermission = 43;
const permission = Array.from({ length: noPermission }, (v, k) => k + 1);

// console.log("permissionpermissionpermission:", permission);
export const links = [
  {
    id: 1,
    name: "home",
    displayName: "Inicio / Calendario",
    path: "/",
    icon: "",
    exact: true,
    component: HomeContainer,
    // profiles: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    permissions: ["nothing"],
  },
  {
    id: 14,
    name: "task/calendar",
    displayName: "Calendario",
    icon: "calendar alternate outline",
    path: "/task/calendar",
    permissions: [47],
  },
  {
    id: 2,
    name: "management",
    displayName: "Administración",
    icon: "setting",
    // profiles: [1, 2],
    permissions: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      44,
      45,
      48,
      49,
      50,
      53,
      54,
      55,
      56,
    ],
    options: [
      {
        id: 3,
        name: "clients",
        displayName: "Clientes",
        icon: "users",
        path: "/clients",
        permissions: [1],
        // profiles: [1, 2]
      },
      {
        id: 55,
        name: "provider",
        displayName: "Proveedores",
        icon: "users",
        path: "/providers",
        permissions: [53],
        // profiles: [1, 2]
      },
      {
        id: 55,
        name: "contact",
        displayName: "Contactos",
        icon: "phone",
        path: "/contacts",
        permissions: [56],
        // profiles: [1, 2]
      },
      {
        id: 4,
        name: "rawmaterials",
        displayName: "Materia Prima",
        icon: "lab",
        path: "/rawmaterials",
        permissions: [2],
        // profiles: [1, 2]
      },
      {
        id: 5,
        name: "materialcontainer",
        displayName: "Compuestos",
        icon: "lab",
        path: "/materials",
        permissions: [3],
        // profiles: [1, 2]
      },
      {
        id: 6,
        name: "formulas",
        displayName: "Fórmulas",
        icon: "lab",
        path: "/formulas",
        permissions: [4],
        // profiles: [1, 2]
      },
      {
        id: 7,
        name: "orders",
        displayName: "Órdenes",
        icon: "shop",
        path: "/orders",
        permissions: [5],
        // profiles: [1, 2]
      },
      {
        id: 59,
        name: "partidas",
        displayName: "Partidas",
        icon: "file text",
        path: "/partidas",
        permissions: [5],
        // profiles: [1, 2]
      },
      {
        id: 57,
        name: "invoice",
        displayName: "Facturación",
        icon: "shop",
        path: "/invoice",
        permissions: [57],
        // profiles: [1, 2]
      },
      {
        id: 58,
        name: "remission",
        displayName: "Remisiones",
        icon: "shop",
        path: "/remission",
        permissions: [58],
        // profiles: [1, 2]
      },
      {
        id: 9,
        name: "users",
        displayName: "Usuarios",
        icon: "users",
        path: "/users",
        permissions: [6],
        // profiles: [1, 2]
      },
      {
        id: 82,
        name: "role_permission",
        displayName: "Roles y permisos",
        icon: "users",
        path: "/role_permission",
        permissions: [7],
        // profiles: [1, 2]
      },
      {
        id: 10,
        name: "glues",
        displayName: "Pegamentos",
        icon: "paste",
        path: "/glues",
        permissions: [8],
        // profiles: [1, 2]
      },
      {
        id: 11,
        name: "freight",
        displayName: "Fletes",
        icon: "car",
        path: "/freight",
        permissions: [9],
        // profiles: [1, 2]
      },
      {
        id: 12,
        name: "color",
        displayName: "Colores",
        icon: "pencil",
        path: "/color",
        permissions: [10],
        // profiles: [1, 2]
      },
      {
        id: 13,
        name: "draw",
        displayName: "Dibujos",
        icon: "sticky note outline",
        path: "/draw",
        permissions: [11],
        // profiles: [1, 2]
      },
      {
        id: 44,
        name: "notificationsettings",
        displayName: "Configuración de Notificación",
        icon: "setting",
        path: "/notification-settings",
        permissions: [12],
        // profiles: [1, 2]
      },
      {
        id: 52,
        name: "notificationtask",
        displayName: "Notificaciones de tareas",
        icon: "alarm",
        path: "/notification-task",
        permissions: [54],
        // profiles: [1, 2]
      },
      {
        id: 45,
        name: "manchetas",
        displayName: "Manchetas",
        icon: "circle outline",
        path: "/manchetas",
        permissions: [13],
        // profiles: [1, 2]
      },
      {
        id: 46,
        name: "generalsettings",
        displayName: "Configuraciones Generales",
        icon: "settings",
        path: "/general-settings",
        permissions: [14],
        // profiles: [1, 2]
      },
      {
        id: 47,
        name: "typemachine",
        displayName: "Tipos de Maquinas",
        icon: "square outline",
        path: "/type-machine",
        permissions: [44],
      },
      {
        id: 48,
        name: "catalogequipment",
        displayName: "Catálogo de maquinaria",
        icon: "warehouse",
        path: "/catalogequipment",
        permissions: [45],
      },
      {
        id: 49,
        name: "tasktype",
        displayName: "Tipos de Tarea",
        icon: "tags",
        path: "/taskType",
        permissions: [48],
      },
      {
        id: 52,
        name: "tasktype",
        displayName: "Tipos de mantenimiento",
        icon: "tags",
        path: "/maintenanceType",
        permissions: [52],
      },
      {
        id: 50,
        name: "isocodes",
        displayName: "Códigos norma ISO",
        icon: "file text",
        path: "/ISOCodes",
        permissions: [49],
      },
      {
        id: 51,
        name: "carpetasbingham",
        displayName: "Carpetas Bingham",
        icon: "folder",
        path: "/FoldersBingham",
        permissions: [50],
      },
      {
        id: 51,
        name: "products",
        displayName: "Productos",
        icon: "database",
        path: "/products",
        permissions: [55],
      },
    ],
  },
  {
    id: 14,
    name: "tasks",
    displayName: "Tareas",
    icon: "tasks",
    permissions: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 35],
    // profiles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 12],
    options: [
      {
        id: 15,
        name: "tasks/check",
        displayName: "Revisión e Incidencias",
        icon: "book",
        path: "/tasks/check",
        permissions: [15],
        // profiles: [1, 2]
      },
      {
        id: 16,
        name: "tasks/windmill",
        displayName: "Molino",
        icon: "play",
        path: "/tasks/windmill",
        permissions: [16],
        // profiles: []
      },
      {
        id: 17,
        name: "tasks/undress",
        displayName: "Desviste",
        icon: "doctor",
        path: "/tasks/undress",
        permissions: [17],
        // profiles: [1, 2, 4]
      },
      {
        id: 18,
        name: "tasks/construction",
        displayName: "Construcción",
        // profiles: [1, 2, 5],
        permissions: [18, 19, 20],
        options: [
          {
            id: 19,
            name: "tasks/construction/rodillos",
            displayName: "Rodillos",
            icon: "building",
            // profiles: [1, 2, 5],
            permissions: [18],
            path: "/tasks/construction/rodillos",
          },
          {
            id: 20,
            name: "tasks/construction/manchetas",
            displayName: "Manchetas",
            icon: "building",
            // profiles: [1, 2, 5],
            permissions: [19],
            path: "/tasks/construction/manchetas",
          },
          {
            id: 43,
            name: "tasks/construction/poliuretanos",
            displayName: "Poliuretanos",
            icon: "building",
            // profiles: [1, 2, 5],
            permissions: [20],
            path: "/tasks/construction/poliuretanos",
          },
          // {
          //   id: 21,
          //   name: 'tasks/construction',
          //   displayName: 'Poliuretano',
          //   icon: 'building',
          //   profiles: [1,2,5],
          //   path: '/tasks/construction',
          // }
        ],
      },
      {
        id: 22,
        name: "tasks/vulcanization",
        displayName: "Vulcanizado",
        icon: "building",
        path: "/tasks/vulcanization",
        permissions: [21],
        // profiles: [1, 2, 6]
      },
      {
        id: 22,
        name: "tasks/detail-vulcanization",
        displayName: "Detalle Vulcanizado",
        icon: "building",
        path: "/tasks/detail-vulcanization",
        permissions: [21],
        // profiles: [1, 2, 6]
      },
      {
        id: 23,
        name: "tasks/unmount",
        displayName: "Desmonte",
        icon: "building",
        path: "/task/unmount",
        permissions: [22],
        // profiles: [1, 2, 12]
      },
      {
        id: 24,
        name: "tasks/roughing",
        displayName: "Desbaste",
        icon: "building",
        path: "/tasks/roughing",
        permissions: [23],
        // profiles: [1, 2, 7]
      },
      {
        id: 25,
        name: "tasks/rectified",
        displayName: "Rectificado",
        icon: "building",
        path: "/tasks/rectified",
        permissions: [24],
        // profiles: [1, 2, 8]
      },
      {
        id: 26,
        name: "tasks/cutting",
        displayName: "Corte",
        icon: "cut",
        path: "/tasks/cutting",
        permissions: [25],
        // profiles: [1, 2, 12]
      },
      // {
      //   id: 27,
      //   name: 'tasks/qa',
      //   displayName: 'Control de calidad',
      //   icon: 'building',
      //   path: '/tasks/qa',
      //   profiles: [1,2,9]
      // },
      // {
      //   id: 27,
      //   name: "tasks/qa",
      //   displayName: "Control de calidad",
      //   icon: "building",
      //   permissions: [26, 27, 28],
      //   // profiles: [1, 2, 9],
      //   options: [
      //     {
      //       id: 40,
      //       name: "tasks/qa-others",
      //       displayName: "Control de calidad - Otros",
      //       icon: "building",
      //       path: "/tasks/qa-others",
      //       permissions: [26],
      //       // profiles: [1, 2, 9]
      //     },
      //     {
      //       id: 41,
      //       name: "tasks/qa-rodillos",
      //       displayName: "Control de calidad - Rodillos",
      //       icon: "building",
      //       path: "/tasks/qa-rodillos",
      //       permissions: [27],
      //       // profiles: [1, 2, 9]
      //     },
      //     {
      //       id: 42,
      //       name: "tasks/qa-manchetas",
      //       displayName: "Control de calidad - Manchetas",
      //       icon: "building",
      //       path: "/tasks/qa-manchetas",
      //       permissions: [28],
      //       // profiles: [1, 2, 9]
      //     },
      //   ],
      // },
      {
        id: 33,
        name: "incidencesreport",
        displayName: "Reporte de incidencias",
        icon: "warning sign",
        path: "/reports/incidences",
        permissions: [35],
        // profiles: [1, 2]
      },
    ],
  },
  {
    id: 28,
    name: "inventories",
    displayName: "Inventarios",
    icon: "history",
    permissions: [29, 30, 31, 32, 33, 34],
    // profiles: [1, 2],
    options: [
      {
        id: 29,
        name: "rawmaterials-stock",
        displayName: "Carga de Materia Prima",
        icon: "lab",
        path: "/rawmaterialsstock",
        permissions: [29],
        // profiles: [1, 2]
      },
      {
        id: 50,
        name: "rawmaterials-discount-stock",
        displayName: "Descarga de Materia Prima",
        icon: "lab",
        path: "/rawmaterialsstockdiscount",
        permissions: [30],
        // profiles: [1, 2]
      },
      {
        id: 30,
        name: "compound-stock",
        displayName: "Carga de Compuestos",
        icon: "lab",
        path: "/compoundstock",
        permissions: [31],
        // profiles: [1, 2]
      },
      {
        id: 49,
        name: "compound-discount-stock",
        displayName: "Descarga de Compuestos",
        icon: "lab",
        path: "/compounddiscountstock",
        permissions: [32],
        // profiles: [1, 2]
      },
      {
        id: 31,
        name: "inventoryrawmaterials",
        displayName: "Inventario Materias Primas",
        icon: "history",
        path: "/inventory/rawmaterials",
        permissions: [33],
        // profiles: [1, 2]
      },
      {
        id: 39,
        name: "compositeinventory",
        displayName: "Inventario de Compuesto",
        icon: "chart pie",
        path: "/reports/compositeinventoryreport",
        permissions: [34],
        // profiles: [1, 2]
      },
    ],
  },
  {
    id: 32,
    name: "reports",
    displayName: "Reportes",
    icon: "archive",
    permissions: [35, 36, 37, 38, 39, 40, 41, 42, 43, 46, 51],
    // profiles: [1, 2, 14],
    options: [
      // {
      //   id: 34,
      //   name: "indicators",
      //   displayName: "Indicadores",
      //   icon: "chart bar",
      //   path: "/reports/indicators",
      //   permissions: [36],
      //   // profiles: [1, 2]
      // },
      {
        id: 34,
        name: "indicators",
        displayName: "Reporte General",
        icon: "chart bar",
        path: "/reports/general",
        permissions: [36],
        // profiles: [1, 2]
      },
      {
        id: 35,
        name: "employeesreport",
        displayName: "Reporte de empleados",
        icon: "user circle outline",
        path: "/reports/employees",
        permissions: [37],
        // profiles: [1, 2]
      },
      {
        id: 36,
        name: "ordersreport",
        displayName: "Reporte de Pedidos",
        icon: "first order",
        path: "/reports/orders",
        permissions: [38],
        // profiles: [1, 2]
      },
      {
        id: 37,
        name: "finishedproduct",
        displayName: "Producto Terminado",
        icon: "shop",
        path: "/reports/finishedproduct",
        permissions: [39],
        // profiles: [1, 2, 14]
      },
      {
        id: 38,
        name: "taskreport",
        displayName: "Reporte de Tareas",
        icon: "tasks",
        path: "/reports/taskreport",
        permissions: [40],
        // profiles: [1, 2]
      },
      {
        id: 47,
        name: "capturereport",
        displayName: "Reporte de Capturas",
        icon: "pencil square",
        path: "/reports/capturereport",
        permissions: [41],
        // profiles: [1, 2]
      },
      {
        id: 48,
        name: "manchetasreport",
        displayName: "Reporte de Manchetas",
        icon: "chevron circle down",
        path: "/reports/manchetasreport",
        permissions: [42],
        // profiles: [1, 2]
      },
      {
        id: 51,
        name: "movimientoinventarioreport",
        displayName: "Reporte de Movimiento de Inventario",
        icon: "chevron circle down",
        path: "/reports/stockmovementsreport",
        permissions: [43],
        // profiles: [1, 2]
      },
      {
        id: 52,
        name: "calendartaskreport",
        displayName: "Reporte de Tareas del Calendario",
        icon: "calendar",
        path: "/reports/calendartaskreport",
        permissions: [46],
        // profiles: [1, 2]
      },
      {
        id: 53,
        name: "foldersbinghamreport",
        displayName: "Documentos generales de Bingham",
        icon: "folder",
        path: "/reports/foldersbingham",
        permissions: [51],
        // profiles: [1, 2]
      },
    ],
  },
];

export const routes = (
  <div>
    <Route path="/" exact component={InicioTarea} />
    <Route path="/orders" component={OrdersContainer} />
    <Route path="/invoice" exact component={InvoiceContainer} />
    <Route path="/remission" exact component={RemissionContainer} />
    <Route path={"/type-machine"} exact component={TypeMachineContainer} />
    <Route
      path={"/catalogequipment"}
      exact
      component={CatalogEquipmentContainer}
    />
    <Route path={"/catalogequipment/:id"} component={EquipmentEditContainer} />
    <Route
      path={"/catalogequipmentInfo/:id"}
      component={EquipmentInfoContainer}
    />
    <Route path="/clients" component={ClientsContainer} />
    <Route path="/providers" exact component={ProvidersContainer} />
    <Route path="/providers/:id" exact component={ProvidersEdit} />
    <Route path="/contacts" exact component={ContactsContainer} />
    <Route path="/taskType" component={TaskTypeContainer} />
    <Route path="/maintenanceType" component={MaintenanceTypeContainer} />
    <Route path="/ISOCodes" component={ISOCodesContainer} />
    <Route path="/FoldersBingham" component={FoldersBinghamContainer} />
    <Route path="/products" component={ProductsContainer} />
    <Route path="/task/calendar" component={CalendarContainer} />
    <Route path="/tasks/check" component={CheckContainer} />
    <Route path="/tasks/windmill" component={WindmillContainer} />
    <Route path="/tasks/undress" component={UndressContainer} />
    <Route
      path="/tasks/construction/rodillos"
      component={ConstructionRodilloContainer}
    />
    <Route
      path="/tasks/construction/manchetas"
      component={ConstructionManchetasContainer}
    />
    <Route
      path="/tasks/construction/poliuretanos"
      component={ConstructionPoliuretanoContainer}
    />
    <Route path="/tasks/vulcanization" component={VulcanizationContainer} />
    <Route path="/tasks/detail-vulcanization" component={DetailVulcanizationContainer}/>
    <Route path={"/tasks/vulcanizadoInfo/:vulcanizationId/:rodilloId"} exact component={VulcanizationTaskInfo} />
    <Route path="/task/unmount" component={UnmountContainer} />
    <Route path="/tasks/cutting" component={CuttingContainer} />
    <Route path="/tasks/roughing" component={RoughingContainer} />
    <Route path="/tasks/rectified" component={RectifiedContainer} />
    <Route path="/tasks/qa-others" component={QAContainerOthers} />
    <Route path="/tasks/qa-manchetas" component={QAContainerManchetas} />
    <Route path="/tasks/qa-rodillos" component={QAContainerRodillos} />
    <Route path="/formulas" component={FormulasContainer} />
    <Route path="/rawmaterials" component={RawMaterialsContainer} />
    <Route path="/materials" component={MaterialContainer} />
    <Route
      path={"/notification-settings"}
      component={NotificationSettingsContainer}
    />
    <Route path={"/notification-task"} component={NotificationTaskContainer} />
    <Route path="/rawmaterialsstock" component={RawMaterialsStockContainer} />
    <Route
      path="/rawmaterialsstockdiscount"
      component={RawMaterialsStockDiscountContainer}
    />
    <Route path="/compoundstock" component={CompoundStockContainer} />
    <Route
      path="/compounddiscountstock"
      component={CompoundDiscountStockContainer}
    />
    {/* <Route path="/profiles" component={ProfilesContainer} />  */}
    <Route path="/users" exact component={UsersContainer} />
    <Route path="/users/:id" component={UsersEditContainer} />
    <Route path="/role_permission" exact component={RolePermissionContainer} />
    <Route
      path="/role_permission/edit/role/:id"
      component={RoleEditContainer}
    />
    <Route
      path="/inventory/rawmaterials"
      exact
      component={InventoryRawMaterialContainer}
    />
    <Route
      path="/reports/calendartaskreport"
      exact
      component={CalendarTaskReportContainer}
    />
    <Route
      path="/reports/foldersbingham"
      exact
      component={FoldersBinghamReportContainer}
    />
    <Route
      path="/reports/iso/codes"
      exact
      component={CalendarTaskIsoReportContainer}
    />
    <Route
      path="/reports/calendartaskreport/:id"
      exact
      component={CalendarTaskTypeReportContainer}
    />
    <Route
      path="/reports/isoreports/:id"
      exact
      component={CalendarTaskIsoCodeReportContainer}
    />
    <Route
      path="/reports/foldersbingham/:id"
      exact
      component={DocumentsBinghamReportContainer}
    />
    <Route
      path="/reports/foldersbinghamTS"
      exact
      component={TechnicalSheetsGeneralBinghamContainer}
    />
    <Route
      path="/reports/technicalSheets/:id"
      exact
      component={TechnicalSheetsBinghamContainer}
    />
    <Route path="/reports/drawFiles/:id" exact component={DrawFilesContainer} />
    <Route
      path="/reports/incidences"
      exact
      component={IncidencesReportContainer}
    />
    <Route path="/reports/indicators" exact component={IndicatorsContainer} />
    <Route path="/reports/general" exact component={GeneralReportContainer} />
    <Route
      path="/reports/incidences/view/:id"
      exact
      component={IncidenceViewContainer}
    />
    <Route
      path="/tasks/incidences/view/:id"
      exact
      component={IncidenceCheckContainer}
    />
    <Route
      path="/reports/employees"
      exact
      component={EmployeesReportContainer}
    />
    <Route path="/glues" exact component={GluesContainer} />
    <Route path="/reports/orders" exact component={OrdersReportContainer} />
    <Route
      path="/reports/finishedproduct"
      exact
      component={FinishedProductReportContainer}
    />
    <Route path="/reports/taskreport" exact component={TaskReportContainer} />
    <Route
      path="/reports/compositeinventoryreport"
      exact
      component={CompositeInventoryReportContainer}
    />
    <Route path="/order/edit/:id" exact component={OrdersEditForm} />
    <Route path="/order/edit/:id/:isIncidence" exact component={OrdersEditForm} />
    <Route path="/freight" exact component={FreightContainer} />
    <Route path="/color" exact component={ColorContainer} />
    <Route path="/draw" exact component={DrawContainer} />
    <Route path={"/manchetas"} exact component={ManchetasContainer} />
    <Route path={"/manchetas/edit/:id"} exact component={ManchetasEdit} />
    <Route
      path={"/general-settings"}
      exact
      component={GeneralSettingsContainer}
    />
    <Route
      path={"/reports/capturereport"}
      exact
      component={CaptureReportContainer}
    />
    <Route
      path={"/reports/manchetasreport"}
      exact
      component={ManchetasReportContainer}
    />
    <Route
      path={"/reports/stockmovementsreport"}
      exact
      component={StockMovementsReportContainer}
    />
    <Route
      path={"/task/desbaste/:id"}
      exact
      component={RoughingContainerTask}
    />
    <Route path={"/task/desmonte/:id"} exact component={UnmountContainerTask} />
    <Route path={"/task/molino/:id"} exact component={WindmillContainerTask} />
    <Route path={"/task/revision/:id"} exact component={CheckContainerTask} />
    <Route path={"/task/desviste/:id"} exact component={UndressContainerTask} />
    <Route
      path={"/task/rectificado/:id"}
      exact
      component={RectifiedContainerTask}
    />
    <Route path={"/task/corte/:id"} exact component={CuttingContainerTask} />
    <Route
      path={"/task/construccion_rodillo/:id"}
      exact
      component={ConstructionRodilloContainerTask}
    />
    <Route
      path={"/task/construccion_manchetas/:id"}
      exact
      component={ConstructionManchetasContainerTask}
    />
    <Route
      path={"/task/construccion_poliuretano/:id"}
      exact
      component={ConstructionPoliuretanoContainerTask}
    />

    <Route
      path={"/task/calidad_rodillo/:id"}
      exact
      component={QAContainerRodillosTask}
    />
    <Route
      path={"/task/calidad_manchetas/:id"}
      exact
      component={QAContainerManchetasTask}
    />
    <Route
      path={"/task/calidad_otros/:id"}
      exact
      component={QAContainerOthersTask}
    />
    <Route path={"/task/vulcanizado/:id"} exact component={VulcanizationTask} />
    <Route path={"/partidas"} exact component={PartidasContainer} />
  </div>
);
